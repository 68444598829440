$primary-color: #42afa8;
$secondary-color: #173f67;
$tertiary-color: #d6d7d9;
$lightest-color: #e5e6e7;
$white: #ffffff;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-family: din-2014, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Verdana', sans-serif;
  color: $secondary-color;
  text-decoration: none;
}

body {
  font-weight: 300;
}

strong {
  font-weight: 500;
}

h1, h2, h3 {
  margin: 1rem 0;
}

h1 {
  font-size: 2rem;
  font-weight: 450;
  text-transform: uppercase;
}

h2 {
  font-size: 1.5rem;
  font-weight: 400;
}

h3 {
  font-weight: 400;
}

main {
  width: 90vw;
  margin: auto
}

ul {
  list-style-type: none;
}

dt {
  display: none;
}

/** Admin Console **/
.admin-console {
  border: solid 1px $primary-color;
  min-height: 300px;
  padding: 1rem;
}

.admin-row {
  overflow: hidden;
  padding: 0.5rem;
  border-bottom: solid 1px $lightest-color;
}

.admin-row h3 {
  display: inline;
}

.icon {
  padding: 0;
  border: 0;
  margin: 0;
  position: relative;
  float: right;
  line-height: 1rem;
  height: 1rem;
}

.icon:hover {
  color: $primary-color;
  background: none;
}

section.newItem {
  margin: 1rem 0;
  
  button {
    margin: 0;
  }
}

.tab, .activeTab {
  margin: auto 0;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}

.projectList {
  h3 {
    font-weight: 450;
  }

  dl {
    width: 100%;
  }
  dt {
    display: block;
    float:left;
    width: 200px;
    text-align: right;
    font-weight: 300;

    &:after {
      content: ": ";
      padding-right: 3px;
    }
  }

  dd {
    float:left;
    width: calc(100% - 200px);

    &:after {
      content: "\A";
      white-space: pre;
    }
  }
}

/** Analysis Console **/
.charts {
  display: flex;
  flex-flow: row wrap;
  width: 80%;
  justify-content: space-around;
}

.charts div {
  margin: 0 auto;
}

.charts > div {
  flex: 0 0 100%;
  display: inline-block;
  margin: 1rem auto 0 auto;
}

.charts .progress-bar {
  text-align: center;
}

.charts .progress-bar svg {
  width: 10rem;
}

@media (max-width: 1000px) {
  .charts > div {
    width: 100%;
  }
}
.google-visualization-tooltip {
  background-color: red !important;
}

/** Layout **/
header > img {
  display: inline-block;
  top: 0;   
  width: 100%;
  padding: 0;
}

main {
  width: 80vw;
  padding: 1vh 0;
  min-height: 100vh;
}

footer {
  display: block;
  height: 11vh;
  line-height: 10vh;
  width: 100vw;
  background-color: $primary-color;
  text-align: center;
  text-transform: uppercase; 
}

footer * {
  font-size: 1.3vw;  
  color: $white;
}

footer sup {
  font-size: 1vw;
  position: relative;
  top: 0.25em;
}

footer span {
  margin: 1.3%;
}

/** Forms **/
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
.datalist,
textarea,
select {
  height: 38px;
  padding: 6px 10px;
  background-color: #fff;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box; 
  margin: 1vh;}
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
textarea {
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px; }
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input.datalist:focus,
textarea:focus,
select:focus {
  border: 1px solid $primary-color;
  outline: 0; }
label,
legend {
  display: block;
  margin: .5rem;
  font-weight: 600; }
fieldset {
  padding: 0;
  border-width: 0; 
  margin-bottom: 3vh;
}
input[type="checkbox"],
input[type="radio"] {
  display: inline; 
}

#scale-note {
  margin: .5rem;
}

input[type=radio] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.request legend {
  font-weight: 800;
}

#practiceAreas {
  margin: 15px;
}

label.checkbox {
  display: inline;
}

#nps label, #leading label {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
}

#nps legend, #leading legend {
  margin-bottom: 0;
}

#timely label {
  width: 8rem;
  height: 2.5rem;
  line-height: 2.5rem;
}

input[type="submit"], button, .button-link {
  width: fit-content;
  height: 2.5rem;
  line-height: 2.5rem;
  margin: 1vh;
  border: 1px solid $primary-color;
  border-radius: 3px;
  padding: 0 1vw;
  background-color: white;
}

.button-link {
  padding: 1vh 1vw;
}

input[type="submit"]:hover, button:hover, .button-link:hover {
  cursor: pointer;
  background-color: $primary-color;
  color: $white;
}

.email-reminder, .email-reminder:hover {
  height: 1rem;
  line-height: 1rem;
  margin: 0;
  border: none;
  padding: 0 0.5em;
  background-color: $white;
}

input {
  margin-right: 1vw;
  margin-left: 0.5vw;
  border-radius: 3px;
  border: 1px solid $primary-color;
}

input:disabled {
  border: 1px solid lightgray;
}

.disabled-label {
  color: lightgray;
}

form {
  display: flex;
  flex-direction: column;
}

.survey-radio label {
  text-align: center;
  display: inline-block;
  margin: 1vh;
  border: 1px solid $primary-color;
  border-radius: 3px;
  font-weight: 400;
}

.survey-radio input[type='radio']:focus + label,
.survey-radio input[type='radio']:checked + label,
.survey-radio label:hover {
  background-color: $primary-color;
  color: white;
  cursor: pointer;
}

textarea {
  margin: 1vh 0;
  width: 90%;
  display: block;
  border-radius: 3px;
  border: 1px solid $primary-color;
}

circle {
  fill: $primary-color;
}

.CircularProgressbar-path {
  fill: $primary-color;
  background-color: $primary-color;
  color: $primary-color;
}

.makeStyles-root-1 * {
  color: $primary-color;
}

.MuiSlider-markLabel {
  color: $secondary-color !important;
  font-weight: 300 !important; 
  margin-left: 10px;
}

.MuiSlider-markLabelActive {
  color: $secondary-color !important;
  font-weight: 400 !important;
}

.multi-select {
  height: auto;
}

#surveyList {
  border-top: 2px solid $primary-color;
  margin: 20px;

  & li {
    margin: 10px;
  }
}

.alert h3 {
  color: $white;
  margin: 1rem 5vw;
  line-height: 2rem;
}

.alert {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  border-radius: 20px;
  background: $primary-color;
  animation: slide 3s;
  opacity: 0.9;
}

@keyframes slide {
  0% {
    top: -10%;
  }
  25% {
    top: 5%;
  }
  75% {
    top: 5%;
  }
  100% {
    top: -10%;
  }
}

#project-number {
  width: 30%;
}

#project-name {
  width: 60%;
}

#pmName, #picName, .name {
  width: 30%;
}

#pmEmail, #picEmail, .email {
  width: 40%;
}

#login-disclaimer {
  margin-top: 20px;
}

#logout {
  float: right;
  padding: 0 1vw;
}